/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import ReactMarkdown from "react-markdown";
import {
  DocumentRefinementTypes,
  DocumentTypes,
  useGetDocumentPromptMutation,
  useGetDocumentRefinementPromptMutation,
} from "../store/apiSlice";
import { showErrorToast } from "../toastUtils";
import { getErrorMessage } from "../error-helpers/errorMessage";
import LoadingPage from "../common/LoadingPage";

interface WriteDocumentProps {
  inputText: string;
  handleDownload: (text: string | null, filename: string) => void;
}

export default function WriteDocumentTab({
  inputText,
  handleDownload,
}: WriteDocumentProps) {
  const [text, setText] = useState<string>(inputText);
  const [refinedText, setRefinedText] = useState<null | string>(null);
  const [selectedPrimaryButton, setSelectedPrimaryButton] =
    useState<DocumentTypes>(DocumentTypes.LETTER);
  const [selectedSecondaryButton, setSelectedSecondaryButton] =
    useState<DocumentRefinementTypes | null>(null);

  const [getDocumentPrompt, { isLoading: documentLoading }] =
    useGetDocumentPromptMutation();
  const [
    getDocumentRefinementPrompt,
    { isLoading: documentRefinementLoading },
  ] = useGetDocumentRefinementPromptMutation();

  const isLoading = documentLoading || documentRefinementLoading;

  const getGptResponse = async (selectedType: DocumentTypes) => {
    try {
      const response = await getDocumentPrompt({
        text: inputText,
        type: selectedType,
      }).unwrap();
      setRefinedText(null);
      setText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const getGptDocumentRefinementResponse = async (
    refinement: DocumentRefinementTypes,
  ) => {
    try {
      const response = await getDocumentRefinementPrompt({
        text,
        refinement,
      }).unwrap();
      setRefinedText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const handlePrimaryButtonClick = (newType: DocumentTypes) => {
    if (isLoading) {
      return;
    }

    setSelectedPrimaryButton(newType);
    setSelectedSecondaryButton(null);
    getGptResponse(newType);
  };

  const handleSecondaryButtonClick = (refinement: DocumentRefinementTypes) => {
    if (isLoading) {
      return;
    }

    setSelectedSecondaryButton(refinement);
    getGptDocumentRefinementResponse(refinement);
  };

  useEffect(() => {
    getGptResponse(DocumentTypes.LETTER);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Centering only the button groups */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "600px",
          width: "100%",
          padding: "16px",
        }}
      >
        <ButtonGroup
          disabled={isLoading}
          variant="outlined"
          aria-label="primary button group"
          sx={{
            mb: 2,
            flexWrap: "wrap", // Allows buttons to wrap to the next line
            "& .MuiButton-root": {
              borderColor: "#c49b40",
              color: "#FFFFFF",
              borderRadius: "24px",
              padding: "6px 16px",
              textTransform: "none",
              transition: "background-color 0.3s, border-color 0.3s",
              margin: "4px", // Adjust margin for wrapping
              "&:hover": {
                borderColor: "#c49b40",
                backgroundColor: "#c49b50",
                color: "#FFFFFF",
              },
              "&.Mui-disabled": {
                color: "#c49b40",
                opacity: 0.6,
              },
            },
            // Media query for small screens
            "@media (max-width: 600px)": {
              "& .MuiButton-root": {
                flex: "1 1 100%", // Stack buttons vertically on small screens
              },
            },
          }}
        >
          <Button
            onClick={() => handlePrimaryButtonClick(DocumentTypes.LETTER)}
            disabled={isLoading}
            sx={{
              backgroundColor:
                selectedPrimaryButton === DocumentTypes.LETTER
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === DocumentTypes.LETTER
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Letter
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(DocumentTypes.REPORT)}
            disabled={isLoading}
            sx={{
              backgroundColor:
                selectedPrimaryButton === DocumentTypes.REPORT
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === DocumentTypes.REPORT
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Report
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(DocumentTypes.ESSAY)}
            disabled={isLoading}
            sx={{
              backgroundColor:
                selectedPrimaryButton === DocumentTypes.ESSAY
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === DocumentTypes.ESSAY
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Essay
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(DocumentTypes.PROPOSAL)}
            disabled={isLoading}
            sx={{
              backgroundColor:
                selectedPrimaryButton === DocumentTypes.PROPOSAL
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === DocumentTypes.PROPOSAL
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Proposal
          </Button>
        </ButtonGroup>

        {selectedPrimaryButton && (
          <ButtonGroup
            disabled={isLoading}
            variant="outlined"
            aria-label="secondary button group"
            sx={{
              flexWrap: "wrap", // Allows buttons to wrap to the next line
              "& .MuiButton-root": {
                borderColor: "#c49b40",
                color: "#FFFFFF",
                borderRadius: "24px",
                padding: "6px 16px",
                textTransform: "none",
                transition: "background-color 0.3s, border-color 0.3s",
                margin: "4px", // Adjust margin for wrapping
                "&:hover": {
                  borderColor: "#c49b40",
                  backgroundColor: "#c49b50",
                  color: "#FFFFFF",
                },
                "&.Mui-disabled": {
                  color: "#c49b40",
                  opacity: 0.6,
                },
              },
              // Media query for small screens
              "@media (max-width: 600px)": {
                "& .MuiButton-root": {
                  flex: "1 1 100%", // Stack buttons vertically on small screens
                },
              },
            }}
          >
            <Button
              onClick={() =>
                handleSecondaryButtonClick(DocumentRefinementTypes.REWRITE)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton === DocumentRefinementTypes.REWRITE
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === DocumentRefinementTypes.REWRITE
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Rewrite
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(DocumentRefinementTypes.FORMAL)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton === DocumentRefinementTypes.FORMAL
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === DocumentRefinementTypes.FORMAL
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Formal
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(DocumentRefinementTypes.INFORMAL)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton === DocumentRefinementTypes.INFORMAL
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === DocumentRefinementTypes.INFORMAL
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Informal
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(DocumentRefinementTypes.MORE_DETAIL)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton ===
                  DocumentRefinementTypes.MORE_DETAIL
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton ===
                  DocumentRefinementTypes.MORE_DETAIL
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              More Detailed
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(DocumentRefinementTypes.LESS_DETAIL)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton ===
                  DocumentRefinementTypes.LESS_DETAIL
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton ===
                  DocumentRefinementTypes.LESS_DETAIL
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Less Detailed
            </Button>
          </ButtonGroup>
        )}
      </Box>

      {/* Text content section remains separately formatted and aligned */}
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          maxWidth: "600px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {isLoading ? (
          <LoadingPage />
        ) : (
          <ReactMarkdown>{refinedText ?? text}</ReactMarkdown>
        )}

        <Button
          variant="contained"
          onClick={() => handleDownload(text, "Edited-document.pdf")}
          sx={{
            mt: 4,
            backgroundColor: "#c49b40",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#b28434",
            },
          }}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
}
