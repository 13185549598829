import React, { useState, useRef, useEffect, useCallback } from "react";
import { useProcessAudioMutation } from "../store/apiSlice";
import { showErrorToast, showWarningToast } from "../toastUtils";
import { Box, Typography, IconButton, Tabs, Tab, Button } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SummarizationTab from "./SummarizationTab";
import PrepareEmailTab from "./PrepareEmailTab";
import LoadingPage from "../common/LoadingPage";
import { Summarize } from "@mui/icons-material";
import { getErrorMessage } from "../error-helpers/errorMessage";
import WriteDocumentTab from "./WriteDocumentTab";
import JsPDF from "jspdf";
import logo from "../logos/logo-white.png";
import AudioWaveForm from "./AudioWaveForm";
import { isMobile } from "react-device-detect";

export const commonTabStyles = {
  border: "1px solid #c49b40",
  borderRadius: "24px",
  padding: "6px 16px",
  margin: "0 8px",
  maxWidth: isMobile ? "30%" : "auto",
  minWidth: isMobile ? "50px" : "275px",
  color: "#FFFFFF",
};

export const selectedTabStyles = {
  "&.Mui-selected": {
    borderColor: "#c49b40",
    backgroundColor: "#c49b40",
    color: "#FFFFFF",
  },
  "&:hover": {
    borderColor: "#c49b40",
    backgroundColor: "#c49b50",
    color: "#FFFFFF",
  },
};

export const tabStyles = { ...commonTabStyles, ...selectedTabStyles };

const AudioRecorder: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [, setAudioUrl] = useState<string | null>(null);
  const [transcribedText, setTranscribedText] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [language] = useState<string>("en");
  const [showCompletedIcon, setShowCompletedIcon] = useState(false);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioBlobRef = useRef<Blob | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [processAudio, { isLoading }] = useProcessAudioMutation();

  const handleProcessAudio = useCallback(async () => {
    if (!audioBlobRef.current) {
      showWarningToast("No audio recorded. Please record audio first.");
      return;
    }

    const formData = new FormData();
    formData.append("audio", audioBlobRef.current, "recording");
    formData.append("language", language);
    try {
      const response = await processAudio(formData).unwrap();
      setTranscribedText(response.transcribedText);
    } catch (error) {
      showErrorToast(`Failed to process audio: ${getErrorMessage(error)}`);
    }
  }, [processAudio, language]);

  const startRecording = useCallback(async () => {
    try {
      let mimeType = "audio/webm";
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      let mediaRecorder;
      try {
        mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });
        mimeType = "audio/webm";
      } catch (e) {
        mediaRecorder = new MediaRecorder(stream, {
          mimeType: "video/mp4",
          audioBitsPerSecond: 128000,
        });
        mimeType = "video/mp4";
      }
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: mimeType,
        });
        audioBlobRef.current = audioBlob;
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        audioChunksRef.current = [];
        clearRecordingTimer();

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }

        handleProcessAudio();
        setShowCompletedIcon(true);
        setTimeout(() => setShowCompletedIcon(false), 2000);
        setHasRecorded(true);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setElapsedTime(0);
      startRecordingTimer();
    } catch (error) {
      showErrorToast(
        `Could not access the microphone. Please check your permissions (${getErrorMessage(error)}).`,
      );
    }
  }, [handleProcessAudio]);

  const startCountdown = () => {
    setCountdown(3);
  };

  useEffect(() => {
    if (countdown === null) return;

    if (countdown > 0) {
      const timer = setTimeout(
        () => setCountdown((prev) => (prev ?? 0) - 1),
        1000,
      );
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      startRecording();
      setCountdown(null);
    }
  }, [countdown, startRecording]);

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const startRecordingTimer = () => {
    recordingTimerRef.current = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);
  };

  const clearRecordingTimer = () => {
    if (recordingTimerRef.current) {
      clearInterval(recordingTimerRef.current);
      recordingTimerRef.current = null;
    }
  };

  const handleDownload = (text: string | null, filename: string) => {
    if (!text) return;

    const doc = new JsPDF();

    doc.setFontSize(12);

    const lines = doc.splitTextToSize(text, 180);
    doc.text(lines, 10, 10);

    doc.save(filename.endsWith(".pdf") ? filename : `${filename}.pdf`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleMicClick = () => {
    if (hasRecorded) {
      setHasRecorded(false);
      setTranscribedText(null);
      setActiveTab(0);
      startCountdown();
    } else {
      startCountdown();
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 4,
        color: "#FFFFFF",
      }}
    >
      <Box
        mt={4}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        onClick={handleMicClick}
        sx={{ cursor: "pointer" }}
        width={"100%"}
      >
        <img src={logo} alt="Logo" style={{ width: "300px", height: "auto" }} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={handleMicClick}
        sx={{ textAlign: "center", mb: 4, cursor: "pointer", mt: 4 }}
      >
        {hasRecorded && (
          <Button color="primary" onClick={handleMicClick} sx={tabStyles}>
            <Box>
              <MicIcon />
              <Typography>Record a new message</Typography>
            </Box>
          </Button>
        )}
      </Box>

      {!hasRecorded && (
        <Box mt={20} mb={20}>
          <IconButton
            color={isRecording ? "secondary" : "primary"}
            onClick={isRecording ? stopRecording : startCountdown}
            sx={{
              mb: 2,
              color: "#FFFFFF",
              transform: "scale(5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              width: "fit-content",
              animation: isRecording ? "pulsate 2s infinite" : "none",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              "@keyframes pulsate": {
                "0%": {
                  transform: "scale(5)",
                  color: "#FFFFFF",
                },
                "50%": {
                  transform: "scale(5.1)",
                  color: "red",
                },
                "100%": {
                  transform: "scale(5)",
                  color: "#FFFFFF",
                },
              },
            }}
          >
            {isRecording ? (
              <StopIcon fontSize="large" />
            ) : (
              <MicIcon
                fontSize="large"
                sx={{
                  color: "#FFFFFF",
                  padding: "4px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
                }}
              />
            )}
          </IconButton>
        </Box>
      )}

      {countdown !== null ? (
        <Typography variant="h6" sx={{ mt: 1 }}>
          {countdown}...
        </Typography>
      ) : isRecording ? (
        <Typography variant="h6" sx={{ mt: 1, color: "red" }}>
          Recording... {elapsedTime}s
        </Typography>
      ) : showCompletedIcon ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <ThumbUpIcon color="success" sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ color: "green" }}>
            Completed!
          </Typography>
        </Box>
      ) : null}
      {isRecording && mediaStreamRef && mediaStreamRef.current && (
        <Box mt={10}>
          <AudioWaveForm mediaStream={mediaStreamRef.current} />
        </Box>
      )}

      {isLoading && <LoadingPage />}

      {transcribedText && (
        <Box>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="inherit"
            scrollButtons
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab label="Summary" icon={<EditNoteIcon />} sx={tabStyles} />
            <Tab label="Write an email" icon={<Summarize />} sx={tabStyles} />
            <Tab label="Write a document" icon={<EditIcon />} sx={tabStyles} />
          </Tabs>

          {activeTab === 0 && transcribedText && (
            <SummarizationTab
              inputText={transcribedText}
              handleDownload={handleDownload}
            />
          )}
          {activeTab === 1 && transcribedText && (
            <PrepareEmailTab
              inputText={transcribedText}
              handleDownload={handleDownload}
            />
          )}
          {activeTab === 2 && transcribedText && (
            <WriteDocumentTab
              inputText={transcribedText}
              handleDownload={handleDownload}
            />
          )}
        </Box>
      )}

      {/* Audio Playback Section
      {audioUrl && (
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: isMobile ? "100%" : "25%",
            margin: "0 auto", // Center the element
          }}
        >
          <audio controls src={audioUrl} style={{ width: "100%" }}>
            Your browser does not support the audio element.
          </audio>
        </Box>
      )}*/}
    </Box>
  );
};

export default AudioRecorder;
