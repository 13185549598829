import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import ReactMarkdown from "react-markdown";
import {
  EmailRefinementTypes,
  EmailTypes,
  useGetEmailPromptMutation,
  useGetEmailRefinementPromptMutation,
} from "../store/apiSlice";
import { showErrorToast } from "../toastUtils";
import { getErrorMessage } from "../error-helpers/errorMessage";
import LoadingPage from "../common/LoadingPage";

interface PrepareEmailTabProps {
  inputText: string;
  handleDownload: (text: string | null, filename: string) => void;
}

const PrepareEmailTab: React.FC<PrepareEmailTabProps> = ({
  inputText,
  handleDownload,
}) => {
  const [text, setText] = useState<string>(inputText);
  const [refinedText, setRefinedText] = useState<null | string>(null);
  const [selectedPrimaryButton, setSelectedPrimaryButton] =
    useState<EmailTypes>(EmailTypes.PROFESSIONAL);
  const [selectedSecondaryButton, setSelectedSecondaryButton] =
    useState<EmailRefinementTypes | null>(null);

  const [getEmailPrompt, { isLoading: emailPromptLoading }] =
    useGetEmailPromptMutation();
  const [getEmailRefinement, { isLoading: emailRefinementLoading }] =
    useGetEmailRefinementPromptMutation();

  const getGptResponse = async (selectedType: EmailTypes) => {
    try {
      const response = await getEmailPrompt({
        text: inputText,
        type: selectedType,
      }).unwrap();
      setRefinedText(null);
      setText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const handlePrimaryButtonClick = (newType: EmailTypes) => {
    if (emailPromptLoading || emailRefinementLoading) {
      return;
    }

    setSelectedPrimaryButton(newType);
    setSelectedSecondaryButton(null);
    getGptResponse(newType);
  };

  const handleSecondaryButtonClick = async (newType: EmailRefinementTypes) => {
    if (emailPromptLoading || emailRefinementLoading) {
      return;
    }

    setSelectedSecondaryButton(newType);
    try {
      const response = await getEmailRefinement({
        text,
        refinement: newType,
      }).unwrap();
      setRefinedText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  useEffect(() => {
    getGptResponse(EmailTypes.PROFESSIONAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Centering only the button groups */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "600px",
          width: "100%",
          padding: "16px",
        }}
      >
        <ButtonGroup
          disabled={emailPromptLoading || emailRefinementLoading}
          variant="outlined"
          aria-label="primary button group"
          sx={{
            mb: 2,
            flexWrap: "wrap", // Allows buttons to wrap to the next line
            "& .MuiButton-root": {
              borderColor: "#c49b40",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "6px 16px",
              textTransform: "none",
              transition: "background-color 0.3s, border-color 0.3s",
              margin: "4px", // Adjust margin for wrapping
              "&:hover": {
                borderColor: "#c49b40",
                backgroundColor: "#c49c50",
                color: "#000000",
              },
              "&.Mui-disabled": {
                color: "#c49b40",
                opacity: 0.6,
              },
            },
            // Media query for small screens
            "@media (max-width: 600px)": {
              "& .MuiButton-root": {
                flex: "1 1 100%", // Stack buttons vertically on small screens
              },
            },
          }}
        >
          <Button
            onClick={() => handlePrimaryButtonClick(EmailTypes.PROFESSIONAL)}
            sx={{
              backgroundColor:
                selectedPrimaryButton === EmailTypes.PROFESSIONAL
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === EmailTypes.PROFESSIONAL
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Professional
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(EmailTypes.FRIENDLY)}
            sx={{
              backgroundColor:
                selectedPrimaryButton === EmailTypes.FRIENDLY
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === EmailTypes.FRIENDLY
                  ? "#ffffff"
                  : "#c49b40",
            }}
          >
            Friendly
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(EmailTypes.CONCISE)}
            sx={{
              backgroundColor:
                selectedPrimaryButton === EmailTypes.CONCISE
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === EmailTypes.CONCISE
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Concise
          </Button>
        </ButtonGroup>

        {selectedPrimaryButton && (
          <ButtonGroup
            disabled={emailPromptLoading || emailRefinementLoading}
            variant="outlined"
            aria-label="secondary button group"
            sx={{
              flexWrap: "wrap", // Allows buttons to wrap to the next line
              "& .MuiButton-root": {
                borderColor: "#c49b40",
                color: "#ffffff",
                borderRadius: "24px",
                padding: "6px 16px",
                textTransform: "none",
                transition: "background-color 0.3s, border-color 0.3s",
                margin: "4px", // Adjust margin for wrapping
                "&:hover": {
                  borderColor: "#c49b40",
                  backgroundColor: "#c49c50",
                  color: "#000000",
                },
                "&.Mui-disabled": {
                  color: "#c49b40",
                  opacity: 0.6,
                },
              },
              // Media query for small screens
              "@media (max-width: 600px)": {
                "& .MuiButton-root": {
                  flex: "1 1 100%", // Stack buttons vertically on small screens
                },
              },
            }}
          >
            <Button
              onClick={() =>
                handleSecondaryButtonClick(EmailRefinementTypes.REWRITE)
              }
              sx={{
                backgroundColor:
                  selectedSecondaryButton === EmailRefinementTypes.REWRITE
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === EmailRefinementTypes.REWRITE
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Rewrite
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(EmailRefinementTypes.FRIENDLIER)
              }
              sx={{
                backgroundColor:
                  selectedSecondaryButton === EmailRefinementTypes.FRIENDLIER
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === EmailRefinementTypes.FRIENDLIER
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Friendlier
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(EmailRefinementTypes.MORE_CONCISE)
              }
              sx={{
                backgroundColor:
                  selectedSecondaryButton === EmailRefinementTypes.MORE_CONCISE
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === EmailRefinementTypes.MORE_CONCISE
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              More Concise
            </Button>
            <Button
              onClick={() =>
                handleSecondaryButtonClick(EmailRefinementTypes.MORE_CONTEXT)
              }
              sx={{
                backgroundColor:
                  selectedSecondaryButton === EmailRefinementTypes.MORE_CONTEXT
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === EmailRefinementTypes.MORE_CONTEXT
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              More Context
            </Button>
          </ButtonGroup>
        )}
      </Box>

      {/* Text content section remains separately formatted and aligned */}
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          maxWidth: "600px",
          padding: "16px",
        }}
      >
        {emailPromptLoading || emailRefinementLoading ? (
          <LoadingPage />
        ) : (
          <ReactMarkdown>{refinedText ?? text}</ReactMarkdown>
        )}

        <Button
          variant="contained"
          onClick={() => handleDownload(text, "Edited-email.pdf")}
          sx={{
            mt: 4,
            backgroundColor: "#c49b40",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#b28434",
            },
          }}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
};

export default PrepareEmailTab;
